.section {
  width: 100%;
 }

.section nav {
  max-width: 100%;
  background-color: black;
  padding: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.24);
}

.section ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-evenly;
}

.section li {
  font-size: 200%;
  padding: 2rem;
}

.section a {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
}

@-webkit-keyframes pop {
  50% {
    transform: scale(1.2);
  }
}
@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

.section a:active,
.section a:hover {
  animation-name: pop;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
}

.section a.active {
  text-decoration: underline;
}
