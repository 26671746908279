@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%; /* 10px -> (1rem = 10px) */
  --yellow: #ffdf00;
  background: black;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "ALOT", sans-serif;
  margin: auto;
  max-width: 2560px;
  width: 100%;
  height: 100%;
}

img {
  display: block;
}