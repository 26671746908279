main {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 102px;
  font-weight: bold;
  background: black;
  min-height: 70vh;
}

.main ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 5px;
  padding: 5px;
}

.main img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media only screen and (max-width: 2000px) {
  .main img {
    max-width: 100%;
    height: auto;
  }
  .main ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .main img {
    max-width: 100%;
    height: auto;
    min-height: 320px;
    object-fit: contain;
  }
  .main ul {
    grid-template-columns: 1fr;
  }
}

.error {
  text-align: center;
  font-size: 20px;
  color: rgb(199, 37, 37);
}

.content {
  padding: 1rem;
  background: white;
  border-top: 2px solid black;
}

.content h1 {
  color: rgb(0, 0, 0);
  margin: 1rem;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 20px;
}

.content p {
  margin: 1rem;
  font-size: 16px;
  font-variant: small-caps;
}

@media only screen and (max-width: 2000px) {
  .content h1 {
    font-size: 18px;
  }

  .content p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .content h1 {
    font-size: 15px;
  }

  .content p {
    font-size: 1.4rem;
  }
}

.content h3 {
  text-align: right;
  font-size: 13px;
  margin: 1rem;
}
