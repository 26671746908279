footer {
  background-color: var(--yellow);
  border-top: 8px solid #000;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding: 0rem;
}

.socials {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socials a {
  margin: 2rem;
}

.socials img {
  float: left;
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.socials a:first-child img {
  width: 58px;
  height: 58px;
  object-fit: cover;
}

.socials a:last-child img {
  width: 62px;
  height: 62px;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .socials img {
    float: left;
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
  .socials a:first-child img {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .socials a:last-child img {
    width: 42px;
    height: 42px;
    object-fit: cover;
  }
}
