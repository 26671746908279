@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  box-sizing: border-box;
  height: 100%;
  font-size: 62.5%; /* 10px -> (1rem = 10px) */
  --yellow: #ffdf00;
  background: black;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "ALOT", sans-serif;
  margin: auto;
  max-width: 2560px;
  width: 100%;
  height: 100%;
}

img {
  display: block;
}
.Menu_section__3ioOE {
  width: 100%;
 }

.Menu_section__3ioOE nav {
  max-width: 100%;
  background-color: black;
  padding: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.12), 0 5px 5px rgba(0, 0, 0, 0.24);
}

.Menu_section__3ioOE ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: space-evenly;
}

.Menu_section__3ioOE li {
  font-size: 200%;
  padding: 2rem;
}

.Menu_section__3ioOE a {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
          transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
}

@-webkit-keyframes Menu_pop__9LRKn {
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes Menu_pop__9LRKn {
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

.Menu_section__3ioOE a:active,
.Menu_section__3ioOE a:hover {
  -webkit-animation-name: Menu_pop__9LRKn;
          animation-name: Menu_pop__9LRKn;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.Menu_section__3ioOE a.Menu_active__3Sv-B {
  text-decoration: underline;
}

.Card_cards__1eFtV {
  margin: 1.5rem;
}

.Card_card__1m44e {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.LoadingSpinner_spinner__2Kyqv {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.LoadingSpinner_spinner__2Kyqv:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--yellow);
  border-color: var(--yellow) transparent var(--yellow) transparent;
  -webkit-animation: LoadingSpinner_spinner__2Kyqv 1.2s linear infinite;
          animation: LoadingSpinner_spinner__2Kyqv 1.2s linear infinite;
}
@-webkit-keyframes LoadingSpinner_spinner__2Kyqv {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes LoadingSpinner_spinner__2Kyqv {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

main {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 102px;
  font-weight: bold;
  background: black;
  min-height: 70vh;
}

.Item_main__3wGDx ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style-type: none;
  margin: 5px;
  padding: 5px;
}

.Item_main__3wGDx img {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media only screen and (max-width: 2000px) {
  .Item_main__3wGDx img {
    max-width: 100%;
    height: auto;
  }
  .Item_main__3wGDx ul {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .Item_main__3wGDx img {
    max-width: 100%;
    height: auto;
    min-height: 320px;
    object-fit: contain;
  }
  .Item_main__3wGDx ul {
    grid-template-columns: 1fr;
  }
}

.Item_error__UcNdX {
  text-align: center;
  font-size: 20px;
  color: rgb(199, 37, 37);
}

.Item_content__TWtcQ {
  padding: 1rem;
  background: white;
  border-top: 2px solid black;
}

.Item_content__TWtcQ h1 {
  color: rgb(0, 0, 0);
  margin: 1rem;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 20px;
}

.Item_content__TWtcQ p {
  margin: 1rem;
  font-size: 16px;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
}

@media only screen and (max-width: 2000px) {
  .Item_content__TWtcQ h1 {
    font-size: 18px;
  }

  .Item_content__TWtcQ p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .Item_content__TWtcQ h1 {
    font-size: 15px;
  }

  .Item_content__TWtcQ p {
    font-size: 1.4rem;
  }
}

.Item_content__TWtcQ h3 {
  text-align: right;
  font-size: 13px;
  margin: 1rem;
}

@font-face {
  font-family: "ALOT";
  src: url(/static/media/A-Love-of-Thunder.ttf.a361d06f.woff) format("woff");
}

header {
  margin: 0 auto;
  padding: 0 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  background-color: black;
}

.Header_header__zSTUo h1 {
  font-size: 28px;
  color: white;
  margin-top: 12rem;
}

@media only screen and (max-width: 600px) {
  .Header_header__zSTUo h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 400px) {
  .Header_header__zSTUo h1 {
    font-size: 19px;
  }
}

.Header_main-logo__2_mi1 {
  margin-top: 2rem;
  display: block;
  width: 12rem;
  height: auto;
}

footer {
  background-color: var(--yellow);
  border-top: 8px solid #000;
  position: fixed;
  width: 100%;
  bottom: 0px;
  left: 0px;
  padding: 0rem;
}

.Footer_socials__lQaaY {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer_socials__lQaaY a {
  margin: 2rem;
}

.Footer_socials__lQaaY img {
  float: left;
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.Footer_socials__lQaaY a:first-child img {
  width: 58px;
  height: 58px;
  object-fit: cover;
}

.Footer_socials__lQaaY a:last-child img {
  width: 62px;
  height: 62px;
  object-fit: cover;
}

@media only screen and (max-width: 600px) {
  .Footer_socials__lQaaY img {
    float: left;
    width: 35px;
    height: 35px;
    object-fit: cover;
  }
  .Footer_socials__lQaaY a:first-child img {
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .Footer_socials__lQaaY a:last-child img {
    width: 42px;
    height: 42px;
    object-fit: cover;
  }
}

