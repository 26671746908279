@font-face {
  font-family: "ALOT";
  src: url("../../assets/fonts/A-Love-of-Thunder.ttf.woff") format("woff");
}

header {
  margin: 0 auto;
  padding: 0 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  background-color: black;
}

.header h1 {
  font-size: 28px;
  color: white;
  margin-top: 12rem;
}

@media only screen and (max-width: 600px) {
  .header h1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 400px) {
  .header h1 {
    font-size: 19px;
  }
}

.main-logo {
  margin-top: 2rem;
  display: block;
  width: 12rem;
  height: auto;
}
